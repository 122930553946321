import React, { useState } from "react"
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from "reactstrap"
import { graphql, Link, useStaticQuery } from "gatsby"

import "./Header.scss"
import { FluidImage } from "./images/FluidImage"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const query = useStaticQuery(graphql`
    query {
      navImage: file(relativePath: { eq: "seklogo_white_se_v3.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <header>
      <Navbar
        style={{ backgroundColor: "black" }}
        expand="lg"
        className="navbar-dark fixed-top"
      >
        <Collapse
          isOpen={isOpen}
          navbar
          className="w-100 dual-collapse2 order-1 order-md-0"
        >
          <Nav className="ml-auto text-center">
            <NavItem active={true}>
              {/* wrapping Gatsby Link in NavLink b/c it's faster performance wise */}
              <Link className="nav-link" to="/about">
                ABOUT
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/">
                HOME
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
        <div
          id="navContainer"
          className="mx-auto my-2 order-0 order-md-1 position-relative"
        >
          <Link className="mx-auto" to="/">
            <FluidImage data={query.navImage} alt={"Sekkei Logo"} />
          </Link>
          <NavbarToggler
            className="mx-auto"
            onClick={toggle}
            data-toggle="collapse"
            data-target=".dual-collapse2"
          >
            <span className="navbar-toggler-icon"></span>
          </NavbarToggler>
        </div>
        <Collapse
          isOpen={isOpen}
          navbar
          className="w-100 dual-collapse2 order-2 order-md-2"
        >
          <Nav className="mr-auto text-center">
            <NavItem active={true}>
              <Link className="nav-link" to="/work">
                WORK
              </Link>
            </NavItem>
            <NavItem>
              <a className="nav-link" href="#contact">
                CONTACT
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  )
}

export default Header
