import React from "react"

import Img from "gatsby-image"
import { ImageProps } from "./IImageProps"

/*
Fluid:
A fluid image will resize according to it's parent container. Basically if screen
gets bigger, the image will get larger :P
*/

export const FluidImage: React.FC<ImageProps> = ({ style, data, alt }) => {
  if (!data?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <Img fluid={data.childImageSharp.fluid} alt={alt} style={style} />
}
