import React from "react"

import Img from "gatsby-image"
import { ImageProps } from "./IImageProps"

/*
Fixed:
A fixed image will stay the same regardless of the size of the parent container and
device width and height. Basically it stays the same size on every device :P
*/

export const FixedImage: React.FC<ImageProps> = ({ style, data, alt }) => {
  if (!data?.childImageSharp?.fixed) {
    return <div>Picture not found</div>
  }

  return <Img fixed={data.childImageSharp.fixed} alt={alt} style={style} />
}
