/*
    Wraps child content between the header and footer tags
*/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import { Footer } from "./Footer"

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  // was being used in the navbar but not really sure how to put alt image text on gatsby image
  //   const data = useStaticQuery(graphql`
  //     query SiteTitleQuery {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  //   `)

  return (
    <>
      <Header />
      {/* <div
        style={
          {
            //keep the stuff in the center column of the page
            //might want to keep this for future pages
            //   margin: `0 auto`,
            //   maxWidth: 960,
            //   padding: `0 1.0875rem 1.45rem`,
          }
        }
      > */}
      <main>{children}</main>
      {/* </div> */}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
