import React from "react"
import "./Footer.scss"
import { useStaticQuery, graphql } from "gatsby"
import { FixedImage } from "./images/FixedImage"

interface Props {}
export const Footer: React.FC<Props> = () => {
  const query = useStaticQuery(graphql`
    query {
      navImage: file(relativePath: { eq: "seklogo_white_se_v3.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      facebookLogo: file(relativePath: { eq: "facebooklogo@4x.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      instagramLogo: file(relativePath: { eq: "instagramlogo@4x.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <body>
      <footer id="contact">
        <div className="container"></div>
        <section className="ft-social">
          <ul className="ft-social-list">
            <li id="fred">
              Bay Area California<br></br> United States
            </li>
            <section className="images">
              <a
                href="https://www.facebook.com/Sekkei-102036375175131"
                target="_blank"
                rel="noopener"
              >
                <FixedImage data={query.facebookLogo} alt={"Sekkei Facebook"} />
              </a>
              <FixedImage data={query.navImage} alt={"Sekkei Logo"} />

              <a
                href="https://www.instagram.com/sekkeicreative/"
                target="_blank"
                rel="noopener"
              >
                <FixedImage data={query.instagramLogo} alt={"Instagram Logo"} />
              </a>
            </section>

            <li id="bob">
              sekkeicreative@gmail.com<br></br> 510 362 3013
            </li>
          </ul>
        </section>

        <section className="ft-legal">
          <ul className="ft-legal-list">
            <li>&copy;2021 Copyright Sekkei Creative</li>
          </ul>
        </section>
      </footer>
    </body>
  )
}
